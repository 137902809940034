import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".swiper-container");
  if (!elms || elms.length == 0) {
    return;
  }

  deps.load("swiper").then((swiper) => {
    elms.forEach((elm) => {
      let config = {};

      if (elm.dataset.config) {
        config = JSON.parse(elm.dataset.config);
        elm.removeAttribute("data-config");
      }

      new swiper.Swiper(elm, config);
      if (elm.dataset.lg) {
        let lgConfig = JSON.parse(elm.dataset.lg);

        deps.load("lightgallery").then(() => {
          lightGallery(elm, lgConfig); // eslint-disable-line
        });
      }
    });
  });
}
