import Vue from "vue";

export default () => {
  if (!Vue.prototype.$yeti) {
    return;
  }

  let companyNumber = Vue.prototype.$yeti.company().phone;
  companyNumber = "+49" + companyNumber.substring(1);
  companyNumber = companyNumber.replaceAll("-", "").replaceAll(" ", "");

  const phoneNumbers = document.querySelectorAll("a[href = 'tel:" + companyNumber + "']");
  if (phoneNumbers) {
    for (const c of phoneNumbers) {
      c.addEventListener("click", () => {
        Vue.prototype.$yeti.trackEvent("contact", "phoneNumber", c.href);
      });
    }
  }

  let companyEmail = Vue.prototype.$yeti.company().email;
  const emailButtons = document.querySelectorAll("a[href = 'mailto:" + companyEmail + "']");
  if (emailButtons) {
    for (const c of emailButtons) {
      c.addEventListener("click", () => {
        Vue.prototype.$yeti.trackEvent("contact", "email", c.href);
      });
    }
  }
}
